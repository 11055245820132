import React, {useState} from 'react';
import './styles.css';

import {Container, Row, Col, Collapse} from 'react-bootstrap'

// import Logo from 'assets/logomarca-at3.svg'
// import Logo from 'assets/logomarca-netsul.png'
import Logo from 'assets/logomarca-jnnet.svg'
// import Logo from 'assets/logomarca-fundo-branco-loga.svg'

import Modal from 'components/Modal'
import OutlineButton from 'components/Button/Outline'
import FormFlow from 'pages/FormFlow'
import Finish from 'pages/Finish'

function App() {
  const [uid, setUid] = useState("")
  const [allData, setAllData] = useState({})

  const [showModal, setShowModal] = useState(null)

  const [finishType, setFinishType] = useState("")

  const props = {
    uid, setUid,
    allData, setAllData,
    finishType, setFinishType,
    showModal, setShowModal
  }

  return (
    <div className="App">
      <header className="App-header">
        <Container fluid>
          <Row className="justify-content-md-center">
            <Col md="12" lg="10" xl="8">
              <img className="App-logo" src={Logo} alt="Logomarca"/>
              <Collapse
                in={!finishType}
              >
                <div id="form-flow">
                <FormFlow 
                  {...props}
                />
                </div>
              </Collapse>
              <Collapse
                in={finishType}
              >
                <div id="finish">
                  <Finish 
                    {...props}
                  />
                </div>
              </Collapse>
            </Col>
          </Row>
          <Modal 
            show={showModal}
            onHide={() => setShowModal(null)}
            header={showModal?.title}
            body={showModal?.message}
            footer={
              <> 
                <OutlineButton 
                  onClick={() => setShowModal(null)}
                >
                  Fechar
                </OutlineButton>
              </>
            }
          />
        </Container>
      </header>
    </div>
  );
}



export default App;
