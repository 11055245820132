const isDEV = false

export const url = isDEV ? "https://assine-homologacao.at3.com.br/api" : '/api'

export const patterns = {
  phone: [
    "(99) 9999-9999",
    "(99) 9 9999-9999",
  ],
  cep: ["99999-999"],
  cpf: ["999.999.999-99"],
  date: ["99/99/9999"]
}

// export const empresa = {
//   nome: "Loga",
//   plural: "dos Logados",
//   singular: "Logado",
//   site: "https://www.loga.net.br",
// }


export const empresa = {
  nome: "JNNET",
  plural: "JNNET",
  singular: "JNNET",
  site: "http://jnnet.com.br",
}

// export const empresa = {
//   nome: "Netsul",
//   plural: "Netsul",
//   singular: "Netsul",
//   site: "http://netsulfibra.com.br",
// }

// export const empresa = {
//   nome: "AT3",
//   plural: "AT3",
//   singular: "AT3",
//   site: "https://www.at3.com.br",
// }
